var render = function render(){
  var _vm$form$documents, _vm$form$documents2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-project-form"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorAction.projectForm,
      expression: "errorAction.projectForm"
    }],
    staticClass: "alert alert-danger"
  }, [_vm._v(_vm._s(_vm.errorAction.projectForm))]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "name",
      "disabled": _vm.disabled,
      "error": _vm.error['name'],
      "label": "Name",
      "type": "text"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "description",
      "disabled": _vm.disabled,
      "error": _vm.fieldErrors['description'],
      "label": "Summary",
      "type": "textarea"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "status",
      "disabled": _vm.disabled,
      "error": _vm.fieldErrors['status'],
      "label": "Status",
      "type": "select",
      "options": _vm.statusOptions
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.status,
      callback: function ($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "startDate",
      "disabled": _vm.disabled,
      "error": _vm.error['startDate'],
      "label": "Start Date",
      "type": "datePicker"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startDate", $$v);
      },
      expression: "form.startDate"
    }
  })], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "endDate",
      "disabled": _vm.disabled,
      "error": _vm.fieldErrors['endDate'],
      "label": "End Date",
      "type": "datePicker"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endDate", $$v);
      },
      expression: "form.endDate"
    }
  })], 1)]), _c('FormGroup', {
    attrs: {
      "id": "cost",
      "disabled": _vm.disabled,
      "description": "Enter the total cost of this project",
      "error": _vm.fieldErrors['cost'],
      "label": "Cost",
      "type": "number"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.cost,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cost", $$v);
      },
      expression: "form.cost"
    }
  }), _vm.mode === 'create' ? _c('FormGroup', {
    attrs: {
      "id": "tasks-template",
      "label": "Tasks Template",
      "type": "select",
      "options": _vm.taskTemplateOptions,
      "description": "Optionally pre-populate the project tasks using a template"
    },
    on: {
      "input": _vm.onSelectTemplate
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-lg-6 col-xl-5 offset-xl-1"
  }, [_c('FormGroup', {
    staticClass: "mb-5",
    attrs: {
      "id": "accountIds",
      "label": "Associated Accounts",
      "description": "Optionally associate accounts with this project",
      "description-position": "top",
      "placeholder": "Add an account...",
      "type": "select-array-list",
      "options": _vm.accountOptions
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.accountIds,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accountIds", $$v);
      },
      expression: "form.accountIds"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "documentIds",
      "label": "Associated Documents",
      "description": "Optionally associate asset documents with this project",
      "description-position": "top",
      "placeholder": "Add an document...",
      "type": "select-array-list",
      "options": _vm.documentOptions,
      "hide-no-options-label": ((_vm$form$documents = _vm.form.documents) === null || _vm$form$documents === void 0 ? void 0 : _vm$form$documents.length) > 0
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.form);
      },
      "click-item": _vm.onClickDocument
    },
    model: {
      value: _vm.form.documentIds,
      callback: function ($$v) {
        _vm.$set(_vm.form, "documentIds", $$v);
      },
      expression: "form.documentIds"
    }
  }), ((_vm$form$documents2 = _vm.form.documents) === null || _vm$form$documents2 === void 0 ? void 0 : _vm$form$documents2.length) > 0 ? _c('div', {
    staticClass: "MultiSelect"
  }, _vm._l(_vm.form.documents, function (document) {
    return _c('div', {
      key: document._id,
      staticClass: "d-flex align-items-center mb-1 MultiSelect-item"
    }, [_c('button', {
      staticClass: "btn btn-link text-danger mr-2",
      on: {
        "click": function ($event) {
          return _vm.removeDocument(document);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times fa-fw"
    })]), _c('div', {
      staticClass: "flex-grow-1 font-w600 MultiSelect-label",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickDocument(Object.assign({}, document, {
            isLegacy: true
          }));
        }
      }
    }, [_vm._v(" " + _vm._s(document.name) + " ")])]);
  }), 0) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }